import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 260,
  '@media (max-width: 480px)': {
    display: 'none',
  },
});

export const ContentWrapper = styled.div({
  margin: '0 9.5%',
  textAlign: 'center',
});

export const TextContainer = styled.div({
  maxWidth: 700,
  margin: '0 auto',
  '& h2': {
    lineHeight: '63px',
    marginBottom: 10,
  },
  '& h4': {
    lineHeight: '26px',
    marginBottom: 50,
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& h4': {
    color: theme.colors.textGray,
  },
}));

export const TableContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const StyledTable = styled.table({
  tableLayout: 'fixed',
  width: 'calc(100% + 132px)',
  marginLeft: -88,
  marginRight: -44,
  borderSpacing: '44px 0',
  position: 'relative',
  fontSize: 21,
  '& th': {
    fontWeight: 'normal',
  },
  '& td': {
    padding: '12px 48px',
  },
  '& thead th': {
    padding: '20px 10px',
  },
  '& th.table-row-heading': {
    textAlign: 'left',
    padding: '12px 60px 12px 0',
    position: 'relative',
    left: 44,
  },
  '& thead th:nth-of-type(n+2)': {
    width: '26%',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 'calc(26% - 57px)',
      height: '100%',
      top: 0,
    },
  },
  '& thead th:nth-of-type(2)': {
    fontWeight: '700',
    '&:before': {
      right: 'calc(52% + 18px)',
    },
  },
  '& thead th:nth-of-type(3)': {
    '&:before': {
      right: 'calc(26% + 31px)',
    },
  },
  '& thead th:nth-of-type(4)': {
    '&:before': {
      right: 44,
    },
  },
},
({ theme }) => ({
  color: theme.colors.textGray,
  '& .table-col-novotalk': {
    color: theme.colors.textHighlighted,
  },
  '& tbody th, & tbody td': {
    borderBottom: `1px solid ${theme.colors.tableBorder}`,
  },
  '& tbody tr:last-child th, & tbody tr:last-child td': {
    borderBottom: 'none',
  },
  '& thead th:nth-of-type(2)': {
    '&:before': {
      boxShadow: theme.shadows.comparisonTableNovotalk,
    },
  },
  '& thead th:nth-of-type(n+3)': {
    '&:before': {
      boxShadow: theme.shadows.comparisonTableOther,
    },
  },
}));
