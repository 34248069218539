import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import PlatformImg1 from '@assets/images/platform/slide1.png';
import PlatformImg2 from '@assets/images/platform/slide2.png';
import PlatformImg3 from '@assets/images/platform/slide3.png';
import PlatformImg4 from '@assets/images/platform/slide4.png';
// import PlatformMobileImg from '@assets/images/platform/slide1_mobile.svg';
import {
  Wrapper, ContentWrapper, TextContainer, AccordionContainer, StyledList, StyledListItem, StyledImage,
  CarouselWrapper, CarouselItemContainer, CarouselItem, SlideText,
  // StyledMobileImage,
} from './styles';

class PlatformSection extends Component {
  constructor() {
    super();
    this.state = {
      showedItemIndex: 0,
    };
    this.desktopImages = [PlatformImg1, PlatformImg2, PlatformImg3, PlatformImg4];
  }

  renderAccordion = (accordionItems) => {
    const { showedItemIndex } = this.state;
    return (
      <StyledList>
        {
          accordionItems.map((item, index) => (
            <StyledListItem
              key={index}
              dangerouslySetInnerHTML={{ __html: item }}
              isItemShown={index === showedItemIndex}
              onClick={() => {
                if (index !== showedItemIndex) {
                  this.setState({ showedItemIndex: index });
                }
              }}
            />
          ))
        }
      </StyledList>
    );
  }

  renderMobileSlider = (items) => {
    const settings = {
      focusOnSelect: true,
      swipeToSlide: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '0',
      dots: true,
    };
    return (
      <CarouselWrapper>
        <Slider {...settings}>
          {items.map((item, index) => (
            <CarouselItemContainer key={index}>
              <CarouselItem>
                {/* <StyledMobileImage src={PlatformMobileImg} alt="" /> */}
                <SlideText dangerouslySetInnerHTML={{ __html: item }} />
              </CarouselItem>
            </CarouselItemContainer>
          ))}
        </Slider>
      </CarouselWrapper>
    );
  }

  render() {
    const { showedItemIndex } = this.state;
    const { content } = this.props;
    const splittedContent = content.split('<hr>');
    const accordionItems = splittedContent.slice(1);
    return (
      <Wrapper>
        <ContentWrapper>
          <TextContainer dangerouslySetInnerHTML={{ __html: splittedContent[0] }} />
          <AccordionContainer>
            {this.renderAccordion(accordionItems)}
            <StyledImage src={this.desktopImages[showedItemIndex]} alt="Platform overview" />
          </AccordionContainer>
          {this.renderMobileSlider(accordionItems)}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

PlatformSection.propTypes = {
  content: PropTypes.string,
};

export default PlatformSection;
