import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, ContentWrapper, StyledImage } from './styles';
import ImageSVG from '../../../../assets/images/emily_home.svg';

const EmilySection = ({ content }) => (
  <Wrapper>
    <ContentWrapper>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </ContentWrapper>
    <StyledImage src={ImageSVG} />
  </Wrapper>
);

EmilySection.propTypes = {
  content: PropTypes.string,
};

export default EmilySection;
