import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../button';
import {
  Wrapper, ContentWrapper, StyledImage, StyledMobileImage,
} from './styles';
import ImageSVG from '../../../../assets/images/hero_home.svg';
import ImageMobileSVG from '../../../../assets/images/hero_home_mobile.svg';

const HeroSection = ({ content }) => (
  <Wrapper>
    <ContentWrapper>
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <Button
        width={120}
        height={32}
        href="/sign-up/index.html"
      >
        Sign up
      </Button>
    </ContentWrapper>
    <StyledImage src={ImageSVG} />
    <StyledMobileImage src={ImageMobileSVG} />
  </Wrapper>
);

HeroSection.propTypes = {
  content: PropTypes.string,
};

export default HeroSection;
