import React from 'react';
import PropTypes from 'prop-types';

import CupSVG from '../../../../assets/icons/advantages_cup.svg';
import DevicesSVG from '../../../../assets/icons/advantages_devices.svg';
import CloudSVG from '../../../../assets/icons/advantages_cloud.svg';
import {
  Wrapper, ContentWrapper, TextContainer, AdvantagesContainer, Advantage, StyledIcon,
} from './styles';

const renderAdvantage = (icon, content) => (
  <Advantage>
    <StyledIcon src={icon} />
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </Advantage>
);

const AdvantagesSection = ({ content }) => {
  const splittedContent = content.split('<hr>');
  return (
    <Wrapper>
      <ContentWrapper>
        <TextContainer dangerouslySetInnerHTML={{ __html: splittedContent[0] }} />
        <AdvantagesContainer>
          {renderAdvantage(CupSVG, splittedContent[1])}
          {renderAdvantage(DevicesSVG, splittedContent[2])}
          {renderAdvantage(CloudSVG, splittedContent[3])}
        </AdvantagesContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

AdvantagesSection.propTypes = {
  content: PropTypes.string,
};

export default AdvantagesSection;
