import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 122,
  position: 'relative',
  '&:after': {
    content: "''",
    display: 'block',
    width: '100%',
    height: 900,
    position: 'absolute',
    top: 120,
    zIndex: -2,
  },
  '@media (max-width: 480px)': {
    marginBottom: 100,
    '&:after': {
      height: 500,
      top: 130,
    },
  },
},
({ theme }) => ({
  '&:after': {
    background: theme.gradients.successStories,
  },
}));

export const ContentWrapper = styled.div({
  maxWidth: 1180,
  minHeight: 370, // return to 540 when we have user pictures,
  margin: '0 auto',
  padding: '36px 100px',
  textAlign: 'center',
  borderRadius: 5,
  '& h2': {
    marginBottom: 60,
  },
  '&:before, &:after': {
    content: "''",
    display: 'block',
    width: '50%',
    height: 25,
    position: 'absolute',
    zIndex: -1,
  },
  '&:before': {
    left: 0,
    top: 120,
    borderBottomLeftRadius: '100% 200%',
  },
  '&:after': {
    right: 0,
    top: 95,
    borderTopRightRadius: '100% 200%',
  },
  '@media (max-width: 480px)': {
    minHeight: 'auto',
    padding: '0 22px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '& h2': {
      marginBottom: 28,
    },
    '&:after': {
      display: 'none',
    },
    '&:before': {
      width: '100%',
      height: 35,
      top: 130,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: '100% 100%',
    },
  },
},
({ theme }) => ({
  backgroundColor: theme.colors.background,
  boxShadow: theme.shadows.successStories,
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '&:before': {
    backgroundColor: theme.colors.background,
  },
  '&:after': {
    backgroundColor: theme.colors.storiesBackground,
  },
}));

export const CarouselWrapper = styled.ul({
  listStyle: 'none',
  '@media (max-width: 900px)': {
    padding: '15px',
    borderRadius: 5,
  },
},
({ theme }) => ({
  '@media (max-width: 900px)': {
    backgroundColor: theme.colors.background,
    boxShadow: theme.shadows.pathItem,
  },
}));

export const CarouselItemContainer = styled.li({
  outline: 'none',
});

export const CarouselItem = styled.div({
  display: 'grid',
  gridTemplateRows: '166px auto',
  gridTemplateColumns: '196px',
  justifyItems: 'center',
  maxWidth: 196,
  '@media (max-width: 900px)': {
    gridTemplateColumns: '100%',
    maxWidth: 'none',
    minHeight: 180, // return to 300 when we have user photos,
    padding: 15,
    borderRadius: 5,
  },
});

export const StoryImg = styled.img({
  alignSelf: 'center',
  borderRadius: '50%',
  objectFit: 'cover',
  transition: 'width 0.5s, height 0.5s, opacity 0.5s',
},
({ theme, isStoryActive }) => ({
  width: isStoryActive ? 166 : 80,
  height: isStoryActive ? 166 : 80,
  boxShadow: isStoryActive ? theme.shadows.successStories : 'none',
  opacity: isStoryActive ? 1 : 0.45,
}));

export const StoryText = styled.div({
  width: 570,
  paddingTop: 28,
  '& p:first-of-type': {
    marginBottom: 18,
  },
  '@media (max-width: 900px)': {
    width: 'auto',
    '& p:first-of-type': {
      marginBottom: 10,
    },
    '& p:first-of-type span': {
      fontSize: 15,
    },
  },
},
({ isStoryActive }) => ({
  opacity: isStoryActive ? 1 : 0,
  transition: isStoryActive ? 'opacity 0.8s 0.2s' : 'opacity 0.1s',
}));

export const DotWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const Dot = styled.div({
  width: 10,
  height: 10,
  border: '1px solid',
  borderRadius: '50%',
  margin: 5,
},
({ theme, current }) => ({
  borderColor: theme.colors.textLightGray,
  backgroundColor: current ? theme.colors.textLightGray : 'none',
}));
