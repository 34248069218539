import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import MainLayout from '../layouts/mainLayout';
import HeroSection from '../components/pages/homePage/hero';
import EmilySection from '../components/pages/homePage/emily';
import StoriesSection from '../components/pages/homePage/stories';
import PathsSection from '../components/pages/homePage/paths';
import AdvantagesSection from '../components/pages/homePage/advantages';
import PlatformSection from '../components/pages/homePage/platform';
import ComparisonSection from '../components/pages/homePage/comparison';
// import BlogSection from '../components/pages/homePage/blog';
import GetStarted from '../components/getStarted';

class IndexPage extends Component {
  getSectionContent = (section) => {
    const { data } = this.props;
    return data[section === 'paths' ? 'cockpitshared' : 'cockpithome'][section].childMarkdownRemark.html;
  }

  render() {
    const { data } = this.props;
    const storiesData = _.shuffle(data.allCockpitstories.edges);
    return (
      <MainLayout paintedMobileHeader>
        {/* TODO: add keywords */}
        <SEO title="Home" keywords={[]} />
        <HeroSection content={this.getSectionContent('hero')} />
        <EmilySection content={this.getSectionContent('emily')} />
        <StoriesSection content={this.getSectionContent('stories')} storiesData={storiesData} />
        {/* <PathsSection content={this.getSectionContent('paths')} /> */}
        <AdvantagesSection content={this.getSectionContent('advantages')} />
        <PlatformSection content={this.getSectionContent('platform')} />
        <ComparisonSection content={this.getSectionContent('comparison')} />
        {/* <BlogSection content={this.getSectionContent('blog')} posts={data.postsData.edges} /> */}
        <GetStarted margin={192} />
      </MainLayout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;

export const query = graphql`{
  cockpithome {
    hero: childCockpithomeHeroTextNode {
      childMarkdownRemark {
        html
      }
    }
    emily: childCockpithomeEmilyTextNode {
      childMarkdownRemark {
        html
      }
    }
    stories: childCockpithomeStoriesTextNode {
      childMarkdownRemark {
        html
      }
    }
    advantages: childCockpithomeAdvantagesTextNode {
      childMarkdownRemark {
        html
      }
    }
    platform: childCockpithomePlatformTextNode {
      childMarkdownRemark {
        html
      }
    }
    comparison: childCockpithomeComparisonTextNode {
      childMarkdownRemark {
        html
      }
    }
  }
  cockpitshared {
    paths: childCockpitsharedPathsTextNode {
      childMarkdownRemark {
        html
      }
    }
  }
  allCockpitstories {
    edges {
      node {
        entry {
          id
          img {
            path
          }
          text
          name
        }
      }
    }
  }
}`;
