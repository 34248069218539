import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 100,
  '@media (max-width: 480px)': {
    marginBottom: 50,
  },
});

export const ContentWrapper = styled.div({
  margin: '0 9.5%',
  textAlign: 'center',
});

export const TextContainer = styled.div({
  maxWidth: 572,
  margin: '0 auto',
  '& h2': {
    lineHeight: '63px',
  },
  '& h4': {
    fontSize: 21,
    lineHeight: '26px',
    marginBottom: 76,
  },
  '@media (max-width: 480px)': {
    '& h4': {
      fontSize: 15,
      marginBottom: 60,
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& h4': {
    color: theme.colors.textDarkGray,
  },
}));

export const AdvantagesContainer = styled.div({
  display: 'grid',
  gridGap: '12%',
  gridTemplateColumns: '1fr 1fr 1fr',
  '@media (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const Advantage = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& h5': {
    marginBottom: 23,
  },
  '& p': {
    fontSize: 18,
    lineHeight: '22px',
  },
  '@media (max-width: 480px)': {
    marginBottom: 70,
    '& h5': {
      fontSize: 18,
    },
    '& p': {
      fontSize: 15,
    },
  },
},
({ theme }) => ({
  '& h5': {
    color: theme.colors.textHighlighted,
  },
  '& p': {
    color: theme.colors.textGray,
  },
}));

export const StyledIcon = styled.img({
  width: 84,
  height: 74,
  objectFit: 'contain',
  marginBottom: 14,
});
