import React from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper, ContentWrapper, TextContainer, TableContainer, StyledTable,
} from './styles';

const renderTable = () => (
  <StyledTable>
    <thead>
      <tr>
        <th scope="col" className="table-row-heading"></th>
        <th scope="col" className="table-col-novotalk">Novotalk</th>
        <th scope="col">Traditional speech therapy</th>
        <th scope="col">Online speech therapy</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row" className="table-row-heading">Clinical oversight</th>
        <td className="table-col-novotalk">Yes</td>
        <td>Yes</td>
        <td>Yes</td>
      </tr>
      <tr>
        <th scope="row" className="table-row-heading">Self learning</th>
        <td className="table-col-novotalk">Yes</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <th scope="row" className="table-row-heading">Availability of structured practice tools</th>
        <td className="table-col-novotalk">
          Online
          <br />
          Anywhere / Anytime
        </td>
        <td>During sessions</td>
        <td>
          In some cases, not on all platforms
        </td>
      </tr>
      <tr>
        <th scope="row" className="table-row-heading">Emotional support</th>
        <td className="table-col-novotalk">Yes</td>
        <td>Sometimes</td>
        <td>Sometimes</td>
      </tr>
      <tr>
        <th scope="row" className="table-row-heading">Fluency maintenance</th>
        <td className="table-col-novotalk">Yes</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <th scope="row" className="table-row-heading">Community</th>
        <td className="table-col-novotalk">Yes</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <th scope="row" className="table-row-heading">Real-life events scenario tools</th>
        <td className="table-col-novotalk">Yes</td>
        <td>No</td>
        <td>No</td>
      </tr>
    </tbody>
  </StyledTable>
);

const ComparisonSection = ({ content }) => (
  <Wrapper>
    <ContentWrapper>
      <TextContainer dangerouslySetInnerHTML={{ __html: content }} />
      <TableContainer>
        {renderTable()}
      </TableContainer>
    </ContentWrapper>
  </Wrapper>
);

ComparisonSection.propTypes = {
  content: PropTypes.string,
};

export default ComparisonSection;
