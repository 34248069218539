import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: -90,
  overflow: 'hidden',
  '@media (max-width: 480px)': {
    marginBottom: 65,
  },
});

export const ContentWrapper = styled.div({
  maxWidth: 600,
  margin: '0 auto 80px auto',
  textAlign: 'center',
  '& h2': {
    marginBottom: 32,
  },
  '& p': {
    fontSize: 25,
    lineHeight: '28px',
    marginBottom: 26,
  },
  '& li': {
    fontSize: 21,
    lineHeight: '25px',
    marginBottom: 36,
    listStyle: 'none',
    position: 'relative',
    left: 55,
    maxWidth: 510,
    '&:before': {
      content: "''",
      display: 'block',
      width: 18,
      height: 18,
      borderRadius: '50%',
      position: 'absolute',
      top: 3,
      left: -40,
    },
  },
  '@media (max-width: 480px)': {
    width: '100%',
    padding: '0 10%',
    marginBottom: 50,
    '& h2': {
      marginBottom: 40,
    },
    '& p': {
      fontSize: 18,
      marginBottom: 26,
    },
    '& li': {
      fontSize: 15,
      marginBottom: 25,
      textAlign: 'left',
      maxWidth: 240,
      '&:before': {
        width: 10,
        height: 10,
        top: 7,
        left: -14,
      },
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& p': {
    color: theme.colors.textDarkGray,
  },
  '& li': {
    color: theme.colors.textGray,
    '&:before': {
      backgroundColor: theme.colors.buttonPrimary,
    },
  },
}));

export const StyledImage = styled.img({
  width: '100%',
  '@media (min-width: 800px)': {
    position: 'relative',
    top: '-215px',
    zIndex: '-1',

  },
  '@media (max-width: 480px)': {
    width: '115%',
    marginLeft: '-15%',
  },
},
({ theme }) => ({
  '@media (min-width: 800px)': {
    boxShadow: theme.shadows.emily,
  },
}));
