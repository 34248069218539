import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import _ from 'lodash';
import Text, { TEXT_COLOR } from '../../../text';

import {
  Wrapper, ContentWrapper, CarouselWrapper, CarouselItemContainer, CarouselItem,
  StoryImg, StoryText, DotWrapper, Dot,
} from './styles';

class StoriesSection extends Component {
  constructor() {
    super();
    this.state = {
      chosenUserIndex: null,
    };
  }

  renderCarousel = () => {
    const { storiesData } = this.props;
    const { chosenUserIndex } = this.state;
    const settings = {
      focusOnSelect: true,
      swipeToSlide: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '0',
      beforeChange: (oldIndex, newIndex) => {
        this.setState({ chosenUserIndex: newIndex });
      },
      autoplay: true,
      autoplaySpeed: 7000,
      onInit: () => {
        this.setState({ chosenUserIndex: 0 });
      },
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: false,
            centerMode: true,
            dots: false,
          },
        },
      ],
    };
    return (
      <CarouselWrapper>
        <Slider {...settings}>
          {storiesData.map(({ node: { entry } }, index) => (
            <CarouselItemContainer key={entry.id}>
              <CarouselItem>
                {/* un-comment next line when we have user photos */}
                {/* <StoryImg src={entry.img.path} isStoryActive={index === chosenUserIndex} /> */}
                <StoryText isStoryActive={index === chosenUserIndex}>
                  <p>
                    <Text fontSize={21} color={TEXT_COLOR.GRAY}>
                      {entry.text}
                    </Text>
                  </p>
                  <p>
                    <Text fontSize={15} color={TEXT_COLOR.LIGHT_GRAY}>
                      {entry.name}
                    </Text>
                  </p>
                </StoryText>
              </CarouselItem>
            </CarouselItemContainer>
          ))}
        </Slider>
      </CarouselWrapper>
    );
  }

  render() {
    const { content } = this.props;
    return (
      <Wrapper>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {this.renderCarousel()}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

StoriesSection.propTypes = {
  content: PropTypes.string,
  storiesData: PropTypes.array,
};

export default StoriesSection;
