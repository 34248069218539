import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 80,
  '@media (max-width: 480px)': {
    minHeight: '100vh',
    marginBottom: 65,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
},
({ theme }) => ({
  '@media (max-width: 480px)': {
    paddingTop: theme.sizes.headerMobileHeight,
    marginTop: -theme.sizes.headerMobileHeight,
    background: theme.colors.backgroundMedium,
    boxShadow: theme.shadows.heroMobile,
  },
}));

export const ContentWrapper = styled.div({
  paddingTop: 110,
  paddingLeft: '20%',
  maxWidth: '90%',
  '& h1': {
    marginBottom: 22,
  },
  '& p': {
    width: '85%',
    marginBottom: 22,
    fontSize: 25,
    lineHeight: '28px',
  },
  '@media (max-width: 480px)': {
    width: '100%',
    maxWidth: '100%',
    textAlign: 'center',
    padding: '75px 6% 0 6%',
    '& p': {
      fontSize: 15,
      margin: '0 auto 43px auto',
    },
  },
},
({ theme }) => ({
  '& h1': {
    color: theme.colors.textHighlighted,
  },
  '& p': {
    color: theme.colors.textDarkGray,
  },
}));

export const StyledImage = styled.img({
  width: '100%',
  position: 'relative',
  top: -32,
  zIndex: -1,
  '@media (max-width: 480px)': {
    display: 'none',
  },
});

export const StyledMobileImage = styled.img({
  width: '100%',
  display: 'none',
  '@media (max-width: 480px)': {
    display: 'block',
  },
});
