import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 100,
  '@media (max-width: 480px)': {
    marginBottom: 100,
  },
});

export const ContentWrapper = styled.div({
  margin: '0 9.5%',
  '@media (max-width: 480px)': {
    textAlign: 'center',
  },
});

export const TextContainer = styled.div({
  maxWidth: 572,
  '& h2': {
    lineHeight: '63px',
    marginBottom: 14,
  },
  '& h4': {
    lineHeight: '26px',
    marginBottom: 52,
  },
  '@media (max-width: 480px)': {
    textAlign: 'center',
    maxWidth: 'none',
    '& h2': {
      marginBottom: 0,
    },
    '& h4': {
      fontSize: 15,
      marginBottom: 30,
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& h4': {
    color: theme.colors.textDarkGray,
  },
}));

export const AccordionContainer = styled.div({
  display: 'grid',
  gridGap: '14%',
  gridTemplateColumns: '1fr 1fr',
  '@media (max-width: 480px)': {
    display: 'none',
  },
});

export const StyledList = styled.ul({
  listStyle: 'none',
  paddingLeft: 34,
});

export const StyledListItem = styled.li({
  minHeight: '45px',
  padding: '0 23px',
  borderRadius: 5,
  position: 'relative',
  marginBottom: 11,
  '& h5': {
    padding: '12px 0',
    lineHeight: '22px',
    cursor: 'pointer',
  },
  '& p': {
    fontSize: 18,
    overflow: 'hidden',
  },
  '&:hover': {
    '& h5': {
      fontWeight: '600',
    },
  },
  '&:before': {
    content: "''",
    display: 'block',
    width: 20,
    height: 20,
    borderRadius: '50%',
    position: 'absolute',
    top: 12,
    left: -36,
  },
},
({ theme, isItemShown }) => ({
  backgroundColor: theme.colors.background,
  boxShadow: theme.shadows.platformOverviewAccordion,
  '& h5': {
    color: theme.colors.textGray,
    fontWeight: isItemShown ? '600' : '400',
  },
  '& p': {
    color: theme.colors.textGray,
    height: isItemShown ? 'auto' : 0,
    paddingBottom: isItemShown ? 15 : 0,
  },
  '&:before': {
    border: isItemShown ? 'none' : `2px solid ${theme.colors.textHighlighted}`,
    backgroundColor: isItemShown ? theme.colors.buttonPrimary : theme.colors.background,
  },
}));

export const StyledImage = styled.img({
  width: '100%',
},
({ theme }) => ({
  boxShadow: theme.shadows.platformOverviewAccordion,
}));


export const CarouselWrapper = styled.ul({
  display: 'none',
  listStyle: 'none',
  '& .slick-dots li button:before': {
    display: 'block',
    content: '""',
    width: 10,
    height: 10,
    borderRadius: '50%',
    opacity: 1,
  },
  '& .slick-dots li.slick-active button:before': {
    border: 'none',
  },
  '@media (max-width: 480px)': {
    display: 'block',
  },
},
({ theme }) => ({
  '& .slick-dots li button:before': {
    border: `1px solid ${theme.colors.textGray}`,
  },
  '& .slick-dots li.slick-active button:before': {
    background: theme.colors.textHighlighted,
  },
}));

export const CarouselItemContainer = styled.li({
  outline: 'none',
});

export const CarouselItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const StyledMobileImage = styled.img({
  maxHeight: 460,
  marginBottom: 24,
});

export const SlideText = styled.div({
  '& h5': {
    fontWeight: '600',
    marginBottom: 10,
  },
  '& p': {
    marginBottom: 25,
  },
},
({ theme }) => ({
  color: theme.colors.textGray,
}));
